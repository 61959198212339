import React from "react";
import "./footer.css";

function Footer() {
  return (
    <footer className="footer">
      <span>Emily "Emma" Lysyk ©2021</span>
    </footer>
  );
}

export default Footer;
